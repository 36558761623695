.wrapper {
    display: flex;
    .content {
        // flex : 1 ;
        width: 100%;
        height: 100%;
        min-height: 100%;
        overflow-x: hidden;
    }
}

.table {
    table {
        border-collapse: collapse;
    }

    th {
        background-color: rgb(245, 244, 244);
        font-weight: 600;
    }

    td,
    th {
        border: 1.2px solid #ccc;
        padding: 8px;
    }
}
