$mobile-width: 480px;
$tablet-width: 768px;
$tablet-large-width : 1024px;


@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content ;
    }
}

@mixin tablet {
    @media only screen and (min-width: 481px) and (max-width: 768px) {
        @content;
    }
}


@mixin tablet-large {
    @media only screen and (min-width: 769px) and (max-width: $tablet-large-width) {
        @content;
    }
}


@mixin desktop {
    @media only screen and (min-width: calc($tablet-large-width + 1px)) {
        @content;
    }
}