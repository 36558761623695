.only_header {
    // display: flex;

    .content {
        // flex : 1 ;
        width: 100%;
        height: 100%;
        min-height: 100%;
    }

}