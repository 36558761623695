// @import 'normalize.css';
@forward "./Responsive.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
:root {
    --default-height-header: 60px;
    --default-width-content: 1150px;
    --primary-color: #088f81;
    --auth-page-color: #5cb8e4;
    --side-color: #12344d;
    --side-icon-color: #dbdedf;
    --side-icon-hover-color: #5a7183;
    --body-color: #f6f6f7;

    --state-sensor-normal: #11cc67;
    --state-sensor-calib: orange;
    --state-sensor-error: red;
    --state-sensor-off: gray;
    // --state-sensor-over: #2192FF;
    --state-sensor-over: #141E61;

    --sate-device-off: gray;
    --sate-device-on: #11cc67;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(
        linear,
        40% 0%,
        75% 84%,
        from(#088f81),
        to(#30ab9f),
        color-stop(0.6, #0fac9d)
    );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    opacity: 0.5;
    cursor: pointer;
}
::selection {
    color: white;
    background: var(--primary-color);
}

// html {
//     font-size: 62.5%;
// }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    background-color: var(--body-color) !important;
    // padding: 0;
    // font-size: 1.6rem;
    // line-height: 1.5;
    // text-rendering: optimizeSpeed;
}

.highcharts-credits {
    display: none;
}

.my_table {
    table {
        border-collapse: collapse;
    }

    th {
        background-color: rgb(245, 244, 244);
        font-weight: 600;
    }

    td,
    th {
        border: 1.2px solid #ccc;
        padding: 8px;
    }
}


// style button close popup map


.mapboxgl-popup-close-button {
    position: absolute;
    right: 4px;
    top: 1px;
    cursor: pointer;
    background-color: transparent;
    font-size: 30px;
}